@charset "utf-8";

// you can override variables like this:
$body-bg: hsl(0, 0%, 98%);
// $body-color: #fff;
$enable-shadows: true;
$btn-focus-width: 10px;
$btn-focus-box-shadow: true;
$enable-negative-margins: true;

// SankeyArt design system, https://www.figma.com/file/J3BIKTtXAgKfDKmeW2sYjT/Branding-Proposal?type=design&node-id=86-820&mode=design&t=ddn3MRpalpXLXDDZ-0
$primary-500: #00a34c; // Is used as bootstrap's --primary .
$primary-800: #053c12;
$secondary-blue: #0077b8;
$secondary-pink: #fdf4fe;
$neutral-0: #ffffff;
$neutral-100: #f4f4f4;
$neutral-200: #f8faf8;
$neutral-300: #d3d3d3;
$neutral-400: #b0b0b0;
$neutral-600: #717171;
$neutral-700: #5e5e5e;
$neutral-900: #222222;
$green-500: $primary-500;
$red-500: #d1003f;
$blue-500: $secondary-blue;
$yellow-500: #b88400;

// Boostrap variables
$success: $primary-500;
$danger: $red-500;
$primary: $primary-500; // Used somewhere by bootstrap (not sure how and where but this needs to be set).

// Bootstrap base file created via these instructions:
// https://getbootstrap.com/docs/5.0/customize/sass/#importing

// Configuration
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/utilities';

// Layout & components
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/accordion';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';

// Helpers
@import '~bootstrap/scss/helpers';

// Utilities
@import '~bootstrap/scss/utilities/api';

:root {
  --primary: #{$primary-500};
  --primary-800: #{$primary-800};
  --secondary-blue: #{$secondary-blue};
  --secondary-pink: #{$secondary-pink};
  --danger: #{$danger};
  --success: #{$success};
  --gray: #{$neutral-400};
  --neutral-0: #{$neutral-0};
  --neutral-100: #{$neutral-100};
  --neutral-200: #{$neutral-200};
  --neutral-300: #{$neutral-300};
  --neutral-400: #{$neutral-400};
  --neutral-600: #{$neutral-600};
  --neutral-700: #{$neutral-700};
  --neutral-900: #{$neutral-900};
  --headings-font-family: 'Montserrat', sans-serif;
  --bs-body-font-family: 'Inter', sans-serif;
  --bs-border-radius: 1rem;
}

a {
  color: var(--secondary-blue);
  text-decoration: none;
  &:hover {
    color: var(--primary-800);
  }
}

.btn {
  --bs-btn-box-shadow: none;
  --bs-btn-border-radius: 8px;
  --bs-btn-padding-x: 2rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-weight: 500;
}

.btn-primary {
  --bs-btn-color: var(--neutral-0);
  --bs-btn-border-color: var(--primary);
  --bs-btn-hover-color: var(--neutral-0);
  --bs-btn-hover-bg: var(--primary-800);
  --bs-btn-hover-border-color: var(--primary-800);
  --bs-btn-disabled-color: var(--neutral-700);
  --bs-btn-disabled-bg: var(--neutral-300);
  --bs-btn-disabled-border-color: var(--neutral-300);
  --bs-btn-disabled-opacity: 1;
}

.btn-secondary {
  --bs-btn-color: var(--primary-800);
  --bs-btn-bg: var(--secondary-pink);
  --bs-btn-border-color: var(--primary-800);
  --bs-btn-border-width: 2px;
  --bs-btn-hover-color: var(--primary-800);
  --bs-btn-hover-bg: #f3e3f5;
  --bs-btn-hover-border-color: var(--primary-800);
  --bs-btn-disabled-color: var(--neutral-400);
  --bs-btn-disabled-bg: var(--secondary-pink);
  --bs-btn-disabled-border-color: var(--neutral-400);
  --bs-btn-disabled-opacity: 1;
}

.btn-lg {
  --bs-btn-border-radius: 20px;
  --bs-btn-padding-y: 12px;
}

.btn-sm {
  --bs-btn-padding-x: 0.75rem;
}

.btn-outline-primary {
  --bs-btn-border-color: var(--primary);
  --bs-btn-color: var(--primary-800);
  --bs-btn-hover-color: var(--neutral-0);
}

.btn-outline-primary-800 {
  --bs-btn-border-color: var(--primary-800);
  --bs-btn-color: var(--primary-800);
  --bs-btn-hover-color: var(--primary);
  --bs-btn-hover-border-color: var(--primary);
}

.btn-padding-sm {
  --bs-btn-padding-x: 1rem;
}

// Important for Sankey nav bar buttons to look better
.btn-outline-primary,
.btn-outline-secondary,
.btn-outline-danger {
  --bs-btn-bg: var(--neutral-0);
}

h1 {
  font-family: var(--headings-font-family);
  font-weight: 600;
}

h2 {
  font-family: var(--headings-font-family);
  font-weight: 600;
  font-size: 1.3125rem; // 21 pt if rem is 16pt
}

h3 {
  font-family: var(--headings-font-family);
}

.modal-header {
  background-color: var(--primary-800);
  color: var(--neutral-0);
}

// Pegasus and SankeyArt
@import 'app/bootstrap/_all';
@import 'sankeyart/sankeyart';
