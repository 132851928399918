// Originally this file was pegasus/bootstrap.sass, however, it is now used for
// the majority of SankeyArt css.
// Classes starting with 'pg' are from Pegasus.

.pg-hidden-mobile-inline
  @extend .d-none, .d-md-inline-block

.pg-text-centered
  @extend .text-center

.pg-text-left
  @extend .text-start

.pg-text-right
  @extend .text-end

.pg-title
  @extend h3

.pg-subtitle
  @extend .lead

.hero-h1
  @extend .lead
  line-height: var(--bs-body-line-height)
  font-family: var(--bs-body-font-family)

// Buttons

.pg-button-primary
  @extend .btn, .btn-primary

.pg-button-danger
  @extend .btn, .btn-outline-danger

.pg-button-light
  @extend .btn, .btn-light

.pg-inline-buttons
  @extend .d-flex

.pg-justify-content-end
  @extend .justify-content-end

.pg-align-items-center
  @extend .align-items-center

.pg-is-loading
  @extend .disabled

.pg-icon
  display: inline-flex
  align-items: center
  justify-content: center
  height: 1.5rem
  width: 1.5rem

// text modifiers

.pg-text-primary
  @extend .text-primary

.pg-text-success
  @extend .text-success

.pg-text-info
  @extend .text-info

.pg-text-danger
  @extend .text-danger

.pg-text-muted
  @extend .text-muted

//  forms

.pg-label
  @extend .form-label

.pg-control
  @extend .form-control

.pg-select
  // for compatibility with bulma we have to introduce an extra parent element
  // to the select class, hence the class being applied to the inner select element
  select
    @extend .form-select

.pg-help
  @extend .form-text

.pg-input-group
  @extend .mb-3

// columns

.pg-columns
  @extend .row, .gy-4

.pg-columns-reversed
  @extend .flex-row-reverse

.pg-column
  @extend .col-md

.pg-column-one-third
  @extend .col-md-4

.pg-column-one-quarter
  @extend .col-md-3

// tables

.pg-table
  @extend .table-striped, .align-middle

  .td, .th
    padding: $table-cell-padding-y $table-cell-padding-x
    border-bottom: $table-border-width solid $table-border-color

  .th
    font-weight: bold

.table-responsive
  td
    white-space: nowrap

// breadcrumbs

.pg-breadcrumbs
  @extend .breadcrumb

  li
    @extend .breadcrumb-item

  .pg-breadcrumb-active
    @extend .active

// cards

.pg-card
  @extend .card, .shadow-sm

.pg-card-image
  @extend .card-img-top

.pg-card-body
  @extend .card-body

// ratios

.pg-ratio-3x2
  @extend .ratio
  --bs-aspect-ratio: 66.6666%

// spacing

.pg-ml
  @extend .ms-1

// badges

.pg-badge-default
  @extend .badge, .rounded-pill, .text-bg-secondary

.pg-badge-success
  @extend .badge, .rounded-pill, .text-bg-success

svg, img
  display: initial

// Collection of SankeyArt classes

.tiny
  font-size: 0.75em

.top-nav-image
  width: auto
  height: 36px

.top-nav-image-link
  margin-right: 8px

.logo-writing-landing-page
  height: auto
  max-width: 200px

.landing-page-img-left
  max-width: 504px

#dropdownProductLinks
  button
    border-width: 0px

  ul
    min-width: 20rem
    border-width: 0px
    font-size: 1rem

  li
    padding-top: 0.5rem
    padding-bottom: 0.5rem
    font-weight: 500

  a
    color: var(--primary-800)

#navbarSupportedContent
  a
    color: var(--primary-800)
    padding-top: 0.5rem
    padding-bottom: 0.5rem
    display: block

#file-dropdown-button, #diagram-list-dropdown-button
  --bs-btn-padding-x: 0.75rem

#testimonials .card
  @extend .py-4, .px-3, .rounded
  max-width: 22rem

.customer-logo-row
  display: block
  margin: 0 auto
  text-align: center

@media (min-width: 768px)
  .customer-logo-row
    display: flex
    flex-wrap: wrap
    justify-content: space-between

.customer-logo
  max-width: 64px
  max-height: 32px
  object-fit: contain
  margin-left: 1.5rem
  margin-right: 1.5rem

@media (min-width: 768px)
  .customer-logo
    margin-left: 0rem
    margin-right: 0rem

@media (min-width: 992px)
  .customer-logo
    max-width: 100px
    max-height: 50px

.pricing-page .customer-logo-row
  max-width: 800px

.pricing-page .customer-logo
  max-width: 70px
  max-height: 35px

#plan-selector-year.is-selected, #plan-selector-month.is-selected
  background-color: var(--primary-800)
  color: var(--neutral-0)
  --bs-btn-hover-border-color: var(--primary-800)
  --bs-btn-hover-color: var(--primary-800)

.testimonial-headshot
  width: 50px
  height: auto

.background-like-disabled
  background-color: #e9ecef !important

.how-discovered-form ul
  padding-left: 0

.how-discovered-form ul li
  list-style-type: none

.subscription-icon:after
  font-family: "Font Awesome 5 Free"
  content: "\f521"
  display: inline-block
  padding-right: 3px
  vertical-align: middle
  font-weight: 900

.blog-container
  max-width: 824px !important

.blog-container H2
  margin-top: 2rem

.dont-break-out
  word-wrap: break-word

.user-list-table th, .user-list-table td
  min-width: 4em
  max-width: 20em

.label-card
  max-width: 26em

#node-colors
  min-height: 4em

.customer-logos li img
  height: min(15vw, 40px)

.faq-item-container
  margin-top: 2rem
  padding-left: min(5vw, 2rem) !important
  padding-right: min(5vw, 2rem) !important
  padding-top: 0
  padding-bottom: 0

.faq-item
  @extend .faq-item-container
  border: solid 1px var(--gray) !important
  border-radius: 10px !important

.faq-item > a, .faq-item-container > a
  padding-top: min(5vw, 2rem)
  padding-bottom: min(5vw, 2rem)
  display: block
  color: var(--bs-body-color)

.faq-item h4
  width: 85%

.faq-item .d-flex::after
  content: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e")
  transform: scale(.6) !important
  width: 2rem !important
  height: 2rem !important

.faq-item a:not(.collapsed) .d-flex::after
  content: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e")

.list-group .row
  margin-left: 0
  margin-right: 0

.anchor-link-target:hover .anchor-link
  visibility: visible
  opacity: 0.5

.anchor-link
  visibility: hidden
  margin-left: 10px
  text-decoration: none
  color: inherit
  opacity: 0

.anchor-link:hover
  opacity: 1 !important

.richtext-image
  max-width: 100%
  height: auto

.complete-screen-width
  width: 100vw
  position: relative
  left: calc(-50vw + 50%)

.small-lr-padding
  padding-left: 0.4rem !important
  padding-right: 0.4rem !important

.color-selector
  max-width: 2.5rem
  max-height: 2rem

.not-displayed
  display: none !important

.not-displayed-toggle
  display: none !important

.label-font
  opacity: 0.65
  transform: scale(0.85)

.top-bar-label
  font-size: calc(0.9 * var(--bs-body-font-size)) !important

.btn-canvas
  height: calc(3.5rem + 2px)
  border: 1px solid #ced4da !important
  text-align: left !important

.footer-col
  margin-bottom: 3rem

.social-icons li
  width: 32px
  height: 32px

// Overwrite the bootstrap container width
@media (min-width: 1400px)
  .container-xxl
    max-width: 1620px !important

.hover-text-light:hover
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important

.node-collapse.collapsing
  transition: all 0s ease

.btn-primary:focus
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.5)

.wide-form-width
  max-width: 19.6em

.small-form-width
  max-width: 9em

#sankey-footer
  box-shadow: 0 50vh 0 50vh #f8f9fa

  a
    color: var(--primary-800)

  a:hover
    color: var(--primary)

  h3
    color: var(--neutral-600)
    font-weight: 600
    font-size: 1rem
    padding-top: 0.4rem
    font-family: var(--bs-body-font-family)
    @extend .pb-lg-3

  li
    padding-top: 3px
    padding-bottom: 3px

.ratings
  margin-right: 10px

.ratings i
  color: #cecece
  font-size: 32px

.rating-color
  color: #fbc634 !important

.small-ratings i
  color: #cecece

.job-testimonial
  color: #374151
  text-decoration: none

.office-icon
  display: inline-flex
  align-items: center
  justify-content: center
  width: calc(1.25rem + 0.75vw)
  height: calc(1.25rem + 0.75vw)

.landing-page h2
  font-weight: 600

@include media-breakpoint-up(xl)
  .landing-page, .pricing-page
    h2
      font-size: 2.5rem

.landing-page ol, .landing-page ul
  list-style-position: inside
  padding-left: 0

#logged-in-base h1, #logged-in-base h2
  color: var(--primary)

#logged-in-base h3
  color: var(--primary-800)

#sign-in-link
  color: var(--primary-800)

#sign-in-link, #dropdownButtonProductLinks
  margin-top: 2px // To vertically align their texts with the sign up button text.

@include media-breakpoint-up(md)
  #sign-in-link
    font-weight: 500

.sign-up-container
  max-width: 32rem

.nav-link
  color: var(--primary-800)

.nav-pills .nav-link.active
  background-color: var(--primary-800)

.bg-batch-danger
  background-color: var(--bs-danger)

.nav-pills .nav-link.active .badge, .nav-pills .nav-link.active .bg-batch-danger
  background-color: var(--primary-800) !important
  border-style: solid
  border-width: 1px
  border-color: var(--neutral-0)

@media (max-width: 576px)
  #pricing-table, #sankey-overview-table
    font-size: 3vw

.pricing-card-title
  @extend h1
  font-size: 3rem
  color: var(--neutral-900) !important

.nr-of-seats-display
  min-height: 1em
  @extend .small, .text-muted

// Avoid linebreak at https://localhost:8000/a/team-name/subscription/
.pricing-and-billing-period
  @extend .small

.sankey-list
  min-width: 16rem
  max-width: 22rem
  margin-right: 2rem

.graph-control-bar
  max-width: none

@media (min-width: 1400px)
  .graph-control-bar
    max-width: 984px

.text-primary-800
  color: var(--primary-800)

.text-neutral-300
  color: var(--neutral-300)

.bg-primary-800
  background-color: var(--primary-800)

.border-primary-800
  border-color: var(--primary-800)

.bg-light-color
  background-color: var(--secondary-pink)

.bg-neutral-3
  background-color: var(--neutral-200)

@media (max-width: 450px)
  .hidden-on-mobile
    display: none !important

.dashboardcard-label
  max-width: 8.5rem !important

.width-fit-content
  width: fit-content

.inside-chart-nav-symbol
  background-color: white
  border-radius: 5px
  position: absolute
  bottom: 12px
  right: 12px

  &:hover
    background-color: rgb(217, 217, 217)
