
.app-card
  @extend .shadow-sm
  margin-bottom: 1em
  width: 100%
  // allow using "notification" class to override color styles
  &:not(.notification)
    background-color: white

// 576px is the bootstrap breaking point "small"
@media (max-width: 575px)
  .app-card
    box-shadow: none !important
    padding-top: 1em
    padding-bottom: 1em
    padding-left: 0em
    padding-right: 0em

@media (min-width: 576px)
  .app-card
    padding: 2em
    border-radius: .3em
